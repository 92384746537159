<template>
	<div class="q-my-sm">
		<q-input
			class="q-mx-md"
			outlined
			v-model="scenario_message_id"
			type="number"
			label="Message ID"
			hint="Optional - create a numeric short code for this message"
			name="scenario_message_id"
			:error="has_message_id_error"
			:error-message="message_id_error_message"
			@input="updateScenarioValidation"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
		<q-btn
			v-if="show_qr_button"
			@click.stop="generateInvokeQR"
			class="q-mt-md"
			flat
			color="primary"
			label="Download QR"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MessageFormsAPI from '@/services/api/MessageForm.js';
import utils from "@/services/utils.js";

export default {
	name: "MessageID",
	data(){
		return{
			valid_number: '',
			existing_number: '',
			has_message_id_error: false,
			message_id_error_message: ''
		}
	},
	computed:{
		...mapGetters(['current_CG', 'invoke_qr_errors']),
		has_sms_invoke_enabled: function () {
			return this.current_CG.customer_group.is_invoke_qr_enabled
		},
		show_qr_button: function(){
			return this.valid_number && this.has_sms_invoke_enabled && !this.existing_number
		},

		scenario_message_id: {
			get(){
				return this.$store.getters.scenario_message_id;
			},
			set(value){
				this.$store.dispatch('updateScenarioMessageId', value);
			}
		},
	},
	methods:{
		async	generateInvokeQR() {
			let qr_data = {
				'phone_number': this.scenario_message_id,
				'sms_text': 'Start'
			}
			const qr_code = await MessageFormsAPI.generateInvokeQR(qr_data);
			if (qr_code.error){
				return
			}
			utils.directDownloadFile('Invoke_QR', 'png', `data:application/png;base64,${qr_code.image_data}`)
		},
		phoneNumberValidator(number) {
			let regex = /^(?:(?:00)?44|0)7(?:[45789]\d{2}|624)\d{6}$/;
			if(number.match(regex)) {
				this.valid_number = true
				return
			}
			this.valid_number = false
		},
		async numberExist(phone_number) {
			this.has_message_id_error = false
			this.message_id_error_message = ''
			let cg_id = this.current_CG.customer_group.id
			const response = await MessageFormsAPI.checkIfNumberExist(cg_id, phone_number);
			this.existing_number = response.error
			if (this.existing_number && response.validation_message){
				this.has_message_id_error = true
				this.message_id_error_message = response.validation_message
			}
		},
		updateScenarioValidation(){
			this.phoneNumberValidator(this.scenario_message_id)
			if (this.scenario_message_id.length === 12 && this.valid_number)
				this.numberExist(this.scenario_message_id)
		}
	},
	created(){
		if (this.scenario_message_id){
			this.updateScenarioValidation()
		}
	},
}
</script>