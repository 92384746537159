<template>
	<div class="q-my-md">
		<q-input
			class="q-mx-md"
			outlined
			v-model="scenario_name"
			label="Message Name"
			hint="Tell us what to call your message. You can use 100 characters"
			name="message_name"
			@input="checkName()"
			:error="has_message_name_error"
			:error-message="message_name_error_message"
		/>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
	name: "MessageName",
	data(){
		return{
			has_message_name_error: false,
			message_name_error_message: '',
		}
	},
	computed:{
		...mapGetters(['is_duplicate_scenario_name','response_message']),
		scenario_name:{
			get(){
				return this.$store.getters.scenario_name;
			},
			set(value){
				this.$store.dispatch('updateScenarioName', value);
			}
		},
	},
	methods: {
		...mapActions(['validateScenarioName', 'updateResponseMessage']),
		async checkName(){
			this.has_message_name_error = false
			this.message_name_error_message = ''
			if (this.scenario_name.length) {
				if (this.scenario_name.length >= 100){
					this.has_message_name_error = true
					this.message_name_error_message = 'Your message name needs to be shorter than 100 characters. '
						+ this.scenario_name.length + '/100'
					return
				}else if(['+', '-', '=', '@'].includes(this.scenario_name[0])){
					this.has_message_name_error = true
					this.message_name_error_message = 'The message name must not start with +, -, = or @ character.'
					return
				}
			}
			this.updateResponseMessage('')
			this.validateScenarioName(this.$route.params.id);
		}
	},
	watch:{
		response_message: function(){
			if (this.response_message && this.is_duplicate_scenario_name){
				this.has_message_name_error = true
				this.message_name_error_message = this.response_message
			}else{
				this.has_message_name_error = false,
				this.message_name_error_message = ''
			}
		}
	}
}

</script>