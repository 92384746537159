<template>
	<div class="router-view-wrapper" v-if="logged_user.customer_permission != 'SENDER'">
		<div class="data-wrapper">
			<InvocationMethod/>
			<MessageType/>
			<MessageName class="pading"/>
			<MessageID class="pading"/>

			<SystemPromptLanguage
				:customer_group_settings="customer_group_settings"
			/>
		</div>
	</div>
</template>

<script>
import InvocationMethod from '@/components/message_forms/settings_tab/InvocationMethod.vue';
import MessageType from '@/components/message_forms/settings_tab/MessageType.vue';
import MessageName from '@/components/message_forms/settings_tab/MessageName.vue';
import MessageID from '@/components/message_forms/settings_tab/MessageID.vue';
import SystemPromptLanguage from '@/components/message_forms/settings_tab/SystemPromptLanguage.vue';
import { mapGetters } from 'vuex';

export default {
	name: "MessageFormSettings",
	components:{
		InvocationMethod,
		MessageType,
		MessageName,
		MessageID,
		SystemPromptLanguage
	},
	props:{
		customer_group_settings: Object
	},
	computed: {
		...mapGetters(['logged_user'])
	},
	methods: {
		getMessage() {
			const errors = this.$store.getters.errors_per_tab['Settings'];
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
	},
	created(){
		this.getMessage()
	}
}
</script>
