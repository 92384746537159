<template>
	<div>
		<q-list class="q-my-sm">
			<q-item tag="label">
				<q-item-section>
					<q-item-label>{{ current_CG.customer_group.session_type_name.test_scenario_name }}</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="scenario_test" :val="true" color="primary" />
				</q-item-section>
			</q-item>

			<q-item tag="label" v-if="scenario_test && current_CG.root_customer_group_permissions.includes('Allow Subset')">
				<q-item-section>
					<q-item-label>{{ current_CG.customer_group.session_type_name.subset_scenario_name }}</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-checkbox v-model="is_subset_scenario" color="primary" />
				</q-item-section>
			</q-item>

			<q-item tag="label">
				<q-item-section>
					<q-item-label>{{ this.current_CG.customer_group.session_type_name.real_scenario_name }}</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="scenario_test" :val="false" color="primary" />
				</q-item-section>
			</q-item>
			<hr>
			<q-item tag="label">
				<q-item-section>
					<q-item-label>Broadcast</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="scenario_type" val="broadcast" color="primary" />
				</q-item-section>
			</q-item>
			<q-item tag="label" v-if="sequence_permission">
				<q-item-section>
					<q-item-label>Sequence</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="scenario_type" val="sequence" color="primary" />
				</q-item-section>
			</q-item>
			<q-item tag="label" v-if="conference_channel">
				<q-item-section>
					<q-item-label>Conference</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-radio v-model="scenario_type" val="conference" color="primary" />
				</q-item-section>
			</q-item>
		</q-list>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'MessageType',
	data(){
		return{
			conference_channel: false,
			sequence_permission: false,
		}
	},
	computed:{
		...mapGetters(['current_CG', 'devices']),
		scenario_type:{
			get(){
				return this.$store.getters.scenario_type;
			},
			set(value){
				let new_val = value;
				if(value == 'conference') {
					if(this.conference_channel) {
						new_val = value;
					}else if(this.sequence_permission) {
						new_val = 'sequence';
					}
				} else if (value == 'sequence') {
					if(this.sequence_permission) {
						new_val = value;
					} else if (this.conference_channel) {
						new_val = 'conference';
					}
				}
				this.$store.dispatch('updateScenarioType', new_val);
				this.clearDevices()
				this.updateSelectAllDevices(true);
				this.updateAllDevicesState({"state": true, "devices_len": this.devices.length});
			}
		},
		scenario_test:{
			get(){
				return this.$store.getters['scenario_test'];
			},
			set(value){
				this.$store.dispatch('updateScenarioTest', value);
				this.validateScenarioName(this.$route.params.id);
			}
		},
		is_subset_scenario:{
			get(){
				return this.$store.getters.is_subset_scenario;
			},
			set(value){
				this.$store.dispatch('updateIsSubsetScenario', value);
			}
		}
	},
	methods:{
		...mapActions(['validateScenarioName', 'clearDevices', 'updateAllDevicesState', 'updateSelectAllDevices']),
		preventDefaultBehaviorBroadcast(value, event) {
			if(!this.conference_channel && !this.sequence_permission) {
				event.preventDefault();
				this.$store.dispatch('updateScenarioType', 'broadcast');
			}
		}
	},
	created(){
		this.conference_channel = this.current_CG.root_customer_group_permissions.includes("Conference bridge");
		this.sequence_permission = this.current_CG.root_customer_group_permissions.includes("Sequence");
	},
}
</script>
