<template>
	<div v-if="show_invocation_method" class="q-my-sm">
		<div v-for="settings in slider_settings" :key="settings.label">
			<q-item tag="label" v-if="settings.condition">
				<q-item-section>
					<q-item-label>{{ settings.label }}</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="settings.model" @input="update(settings.model, settings.action)"/>
				</q-item-section>
			</q-item>
		</div>
		<hr>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'InvocationMethod',
	data(){
		return{
			has_phone_invoke_permission: false,
			has_incident_manager_permission: false,
			has_email_invoke_permission: false,
			show_invocation_method: true
		}
	},
	computed:{
		...mapGetters([
			'current_CG',
			'is_dashboard',
			'is_phone_invoke',
			'is_incident_manager',
			'is_email_invoke'
		]),
		slider_settings(){
			return [
				{
					"condition": true,
					"label": 'Dashboard',
					"model": this.is_dashboard,
					"action": 'updateInvocationMethodIsDashboard'
				},
				{
					"condition": this.has_phone_invoke_permission,
					"label": 'Phone Invoke',
					"model": this.is_phone_invoke,
					"action": 'updateInvocationMethodIsPhoneInvoke'
				},
				{
					"condition": this.has_email_invoke_permission,
					"label": 'Email Invoke',
					"model": this.is_email_invoke,
					"action": 'updateInvocationMethodIsEmailInvoke'
				},
				{
					"condition": this.has_incident_manager_permission,
					"label": 'Incident Manager',
					"model": this.is_incident_manager,
					"action": 'updateInvocationMethodIsIncidentManager'
				},
			]
		}
	},
	methods:{
		...mapActions([
			'updateInvocationMethodIsDashboard',
		]),
		update(settings_model, settings_action){
			this.$store.dispatch(settings_action, settings_model);
			if(!this.is_phone_invoke && !this.is_incident_manager && !this.is_email_invoke){
				this.$store.dispatch('updateInvocationMethodIsDashboard', true);
			}
		}
	},
	created(){
		this.has_phone_invoke_permission = this.current_CG.root_customer_group_permissions
			.includes('Phone invoke');
		this.has_incident_manager_permission = this.current_CG.root_customer_group_permissions
			.filter(function(permission) { return permission.includes('Incident Manager'); })
			.length > 0;
		this.has_email_invoke_permission = this.current_CG.root_customer_group_permissions
			.includes('Email invoke');
		this.show_invocation_method = this.has_phone_invoke_permission || 
			this.has_incident_manager_permission || this.has_email_invoke_permission
	}
}
</script>