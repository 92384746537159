<template>
	<div v-if="((scenario_type ==='broadcast' || scenario_type === 'sequence') && available_languages.length != 1)">
		<div class="q-py-sm">
			<q-select
				class="q-mb-sm padding"
				:hint="available_languages.length == 1 ? 'The following language will be used for the system prompts:' : 'Please choose language for the system prompts:'"
				label="System prompt language"
				v-model="selected_language"
				:options="available_languages"
				:disable="available_languages.length == 1 ? true : false"
			/>
			<a class="cursor-pointer padding" @click="scrollToLanguage"><u>Change language?</u></a>
		</div>
	</div>
</template>

<script>
import message_forms_utils from '@/utils/message_forms.js';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: "SystemPromptLanguage",
	data(){
		return{
			available_languages: [],
			customer_group_settings: {},
		}
	},
	computed:{
		...mapGetters(['current_CG', 'scenario_type', 'tts_voice', 'people']),
		selected_language:{
			get(){
				return this.$store.getters.selected_language;
			},
			set(val){
				if (this.selected_language != ''){
					if (val == 'English'){
						this.updateTTSVoiceSelected(this.people[1]);
					}
					else{
						for (let voice in this.people){
							if(this.people[voice]['language'] != undefined && this.people[voice]['language'] == val){
								this.updateTTSVoiceSelected(this.people[voice]);
								break;
							}
						}
					}
				}
				else{
					this.updateTTSVoiceSelected(this.people[1]);
				}
				this.$store.dispatch('updateSelectedLanguage', val);
			}
		}
	},
	methods:{
		...mapActions(['updateSelectedLanguage', 'updateTTSVoiceSelected', 'updatePeopleList']),
		setSelectedLanguage() {
			if(this.selected_language != ""){
				this.updateSelectedLanguage(this.selected_language);
			}
			else if(this.customer_group_settings['default_tts_voice'] != "" && this.customer_group_settings['default_tts_voice'] != undefined && this.customer_group_settings['default_tts_voice']['language']){
				this.updateSelectedLanguage(this.customer_group_settings['default_tts_voice']['language']);
				this.updateTTSVoiceSelected(this.customer_group_settings['default_tts_voice']);
			}
			else{
				this.updateSelectedLanguage(message_forms_utils.default_people()[1]['language']);
				this.updateTTSVoiceSelected(message_forms_utils.default_people()[1]);
			}
		},
		scrollToLanguage(){
			this.$router.push({"name": "Message Content" })
		},
		getCurrentCgSettings(){
			if (!this.current_CG.customer_group.settings) {
				this.customer_group_settings = {};
			} else {
				this.customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
			}
		}
	},
	mounted(){
		this.available_languages = message_forms_utils.build_available_languages(this.current_CG.root_customer_group_permissions);
		this.setSelectedLanguage();
	},
	created(){
		this.getCurrentCgSettings()
	},
}
</script>
